import client from './index';
import store from '@/store';

const getDataWithParams = (type, url, request) =>
  client[type].get(url, { params: request });
const getData = (type, url, options = {}) => client[type].get(url, options);
const postData = (type, url, data, options = {}) =>
  client[type].post(url, data, options);
const putData = (type, url, data, options = {}) =>
  client[type].put(url, data, options);
const patchData = (type, url, data) => client[type].patch(url, data);
const destroyData = (type, url, options = {}) =>
  client[type].delete(url, options);
const uploadData = (type, url, data) =>
  client[type].post(url, data, {
    'Content-Type': 'multipart/form-data',
    onUploadProgress: (progressEvent) => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      // store.dispatch('setLoading', percentCompleted);
    },
  });
const downloadFile = (url) =>
  client['api'].get(url, {
    responseType: 'blob',
    headers: { mode: 'no-cors' },
  });

export {
  getDataWithParams,
  getData,
  postData,
  patchData,
  destroyData,
  uploadData,
  downloadFile,
  putData,
};
