import { getData } from '@/store/config/method';

const initialState = () => ({
  priority: [],
  status: null,
  message: null,
});
const state = initialState;

const getters = {
  getPriority: (state) => state.priority,
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
};

const actions = {
  async setPriorityData({ commit, rootState } ) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData('api', 'v1/priorities-dropdown', option);
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },

  // async postPriorityData({ commit, rootState }, payload) {
  //   try {
  //     const option = {
  //       headers: {
  //         Authorization: 'Bearer ' + rootState.auth.token,
  //       },
  //     };
  //     const response = await postData('api', 'v1/brrd', payload, option);
  //     commit('SET_RESPONSE', response);
  //   } catch (_) {
  //     commit('SET_FAILED_LOGIN');
  //   }
  // },

  resetSearch({ commit }) {
    commit('resetState');
  },
};

const mutations = {
  SET_RESPONSE(state, response) {
    state.status = response.data.status;
    state.message = response.data.msg;
  },
  SET_FAILED(state) {
    state.message = 'Network Problem';
    state.status = 0;
  },

  SET_FAILED_MESSAGE(state, error) {
    if (error.status == 400) {
      state.message = 'Nomor KTP atau NIK Anda Salah';
      state.status = 0;
    } else {
      state.message = 'Network Problem';
      state.status = 0;
    }
  },
  SET_FAILED_LOGIN(state) {
    state.message = 'Nomor KTP atau NIK Anda Salah';
    state.status = 0;
  },
  SET_CONTENT_DATA(state, response) {
    state.priority = response;
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
