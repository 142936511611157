import moment from "moment";

function convertDateTime(value) {
    moment.locale('id')
    return moment(value).format('dddd, DD MMM YYYY | HH:mm'); 
}
function convertTime(value) {
    moment.locale('id')
    return moment(value, 'hh:mm:ss').format('HH:mm'); 
}
function convertDate(value) {
    moment.locale('id')
    return moment(value).format('DD MMM YYYY'); 
}
function diffDate(begin,end){
    var dateBegin = moment(begin, 'DD-MM-YYYY'); 
    var dateEnd = moment(end, 'DD-MM-YYYY');
    return dateEnd.diff(dateBegin, 'days')
}

const diffDateTime = (begin,end) =>{
    const dateBegin = moment(begin); 
    const dateEnd = moment(end);
    return dateEnd.diff(dateBegin, 'minutes')
}

function formatDate(value){
    return moment(value).format('YYYY-MM-DD')
}
function commonDateTimeFormat(value){
    return moment(value).format('DD MMM YYYY')
}
function dashboardDateTime(value) {
    return moment(value).format('dddd, DD MMM YYYY'); // Saturday, 25-04-2020
}
function localeDateTime(value){
    moment.locale('id')
    return moment(value).format('dddd, DD MMM YYYY'); 
}
function convertDatetoStringDate(value){
    return moment(value).format('ddd'); //sunday
}
function monthOnly(value){
    return moment(value).format('MMM'); //jan
}
function dateOnly(value){
    return moment(value).format('DD'); //jan
}
function todayDate(){
    return moment().format('YYYY-MM-DD')
}
function timeFormat(value){
    var temp = value.split(':')
    return temp[0] + ":" + temp[1]
}
function tomorrow(){
    var today = moment();
    var tomorrow = moment(today).add(1, 'days');
    return new Date(formatDate(tomorrow))
}
function addDate(date,dayAdd){
    var resutlDate = moment(date).add(dayAdd, 'days');
    return new Date(formatDate(resutlDate))
}
export {
    convertDateTime,
    convertDate,
    convertTime,
    dashboardDateTime,
    convertDatetoStringDate,
    monthOnly,
    dateOnly,
    timeFormat,
    diffDate,
    formatDate,
    tomorrow,
    commonDateTimeFormat,
    addDate,
    todayDate,
    localeDateTime,
    diffDateTime
}