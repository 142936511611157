<template>
  <div class="e-switch">
    <label class="switch">
      <input :value="isChecked" type="checkbox" @input="onInput($event)" />
      <span class="slider round" />
    </label>
  </div>
</template>
<script>
export default {
  props: {
    isChecked: {
      default: false,
    },
  },

  methods: {
    onInput(e) {
      this.isChecked = !this.isChecked;
      this.$emit('onInput', e);
    },
  },
};
</script>
