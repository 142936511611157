<template>
    <div id="modal" class="r-isfull-position">
        <div :class="`s-modal s-modal-${size}`">
            <div :class="`s-modal-header s-bg-${header} s-center-flex`">
                <h5 :class="`s-text-${header}-text`">{{title}}</h5>
                <div class="s-ml-auto s-cursor-pointer" @click.prevent="closeModal()">
                    <img :src="require('@/assets/icons/icon-x.svg')">
                </div>
            </div>
            <div class="s-modal-content">
                <slot name="modalContent"></slot>
            </div>
            <div v-if="footer" :class="`s-modal-footer s-bg-${footerColor}`">
                <div class="s-p-16">
                    <slot name="modalFooter"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props:{
        size:{
            default: 'medium'
        },
        header:{
            default: 'primary'
        },
        title:{
            default: '',
            require: true
        },
        footer: {
            default: false
        },
        footerColor: {
            default: 'white'
        }
    },
    methods: {
        ...mapActions({
            setModal : 'setModal'
        }),
        closeModal(){
            this.setModal(null)
        }
    }
}
</script>