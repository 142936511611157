import { postData, getData, putData, destroyData } from '@/store/config/method';

const initialState = () => ({
  expert_matching: [],
  status: null,
  message: null,
  request: { ...requestInit },
});
const requestInit = {
  page: 1,
  show: 10,
  sortBy: null,
  sorting: null,
  search: null,
};
const state = initialState;

const getters = {
  getExpertMatching: (state) => state.expert_matching,
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
  getRequest: (state) => state.request,
};

const actions = {
  async setExpertMatchingData({ commit, rootState, state}) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
        params: state.request,
      };
      const response = await getData('api', 'v1/expert-matchings', option);
      commit('SET_EXPERT_MATCHING_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async setExpertMatchingStakeholderData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData(
        'api',
        `v1/expert-matchings/${payload}/expert-responses`,
        option
      );
      commit('SET_EXPERT_MATCHING_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async setAvailableRequestData({ commit, rootState }) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData(
        'api',
        'v1/my-expert-matching-offers',
        option
      );
      commit('SET_EXPERT_MATCHING_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async setExpertMatchingSingleData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData(
        'api',
        `v1/expert-matchings/${payload}`,
        option
      );
      commit('SET_EXPERT_MATCHING_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async onVerifExpert({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await putData(
        'api',
        `v1/expert-responses/${payload.id}/${payload.status}`,
        null,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },

  async onAcceptOffer({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData(
        'api',
        `v1/expert-responses`,
        payload,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async setSignUpRequestData({ commit, rootState }) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData('api', `v1/my-responses`, option);
      commit('SET_EXPERT_MATCHING_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async signUpExpertMatching({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData(
        'api',
        `v1/expert-responses`,
        payload,
        option
      );
      commit('SET_EXPERT_MATCHING_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async setExpertMatchingDataPublicData({ commit }) {
    try {
      const response = await getData('api', 'v1/public/expert-matchings');
      commit('SET_EXPERT_MATCHING_DATA', response.data.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async postExpertMatchingData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData(
        'api',
        'v1/expert-matchings',
        payload,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
async updateExpertMatchingData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await putData(
        'api',
        `v1/expert-matchings/${payload.id}`,
        payload,
        option
      );

      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },

  // async onVerify({ commit, rootState }, payload) {
  //   try {
  //     const option = {
  //       headers: {
  //         Authorization: 'Bearer ' + rootState.auth.token,
  //       },
  //     };
  //     const response = await putData(
  //       'api',
  //       `v1/events/${payload.id}/${payload.action}`,
  //       null,
  //       option
  //     );
  //     console.log('response', response);
  //     commit('SET_RESPONSE', response);
  //   } catch (_) {
  //     commit('SET_FAILED');
  //   }
  // },

  async onDelete({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await destroyData(
        'api',
        `v1/expert-matchings/${payload}`,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  resetSearch({ commit }) {
    commit('resetState');
  },
};

const mutations = {
  SET_RESPONSE(state, response) {
    state.status = response.data.status;
    state.message = response.data.msg;
  },
  SET_FAILED(state) {
    state.message = 'Network Problem';
    state.status = 0;
  },

  SET_FAILED_MESSAGE(state, error) {
    if (error.status == 400) {
      state.message = 'Nomor KTP atau NIK Anda Salah';
      state.status = 0;
    } else {
      state.message = 'Network Problem';
      state.status = 0;
    }
  },
  SET_FAILED_LOGIN(state) {
    state.message = 'Nomor KTP atau NIK Anda Salah';
    state.status = 0;
  },
  SET_EXPERT_MATCHING_DATA(state, response) {
    state.expert_matching = response;
    //add property open dropdown
    state.expert_matching.forEach(function (element) {
      element.open_dropdown = 'false';
    });
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
