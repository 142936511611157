import { postData, getData } from '@/store/config/method';

const initialState = () => ({
  user: {},
  role: null,
  status: 0,
  token: null,
  expired_at: null,
  message: '',
});
const state = initialState;

const getters = {
  getUser: (state) => state.user,
  getRole: (state) => state.role,
  getStatus: (state) => state.status,
  getMessage: (state) => state.message,
  getToken: (state) => state.token,
  getExpiredAt: (state) => state.expired_at,
};

function makeid() {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 20; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const actions = {
  async login({ commit }, data) {
    try {
      const response = await postData('api', 'v1/login', data);
      commit('SET_RESPONSE', response.data);
      commit('SET_LOGIN_DATA', response.data.data);
    } catch (e) {
      // commit('SET_RESPONSE', e);

      commit('SET_FAILED_LOGIN', e);
    }
  },
  async setUserData({ commit, rootState }) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData('api', 'v1/mydetail', option);
      commit('SET_USER', response.data);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async register({ commit }, data) {
    try {
      const response = await postData('api', 'v1/register', data);
      commit('SET_RESPONSE', response.data);
    } catch (_) {
      commit('SET_FAILED_REGISTER');
    }
  },
  async forgotPassword({ commit }, payload) {
    try {
      const response = await postData('api', 'v1/forgot-password', payload);
      commit('SET_RESPONSE', response.data);
    } catch (_) {
      commit('SET_FAILED_REGISTER');
    }
  },
  async changePassword({ commit }, payload) {
    try {
      const response = await postData('api', 'v1/renew-password', payload);
      commit('SET_RESPONSE', response.data);
    } catch (_) {
      commit('SET_FAILED_REGISTER');
    }
  },
  async logoutUser({ commit, rootState }) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData('api', 'v1/logout', option);
      commit('SET_RESPONSE', response.data.msg);
    } catch (err) {
      if (err.response) {
        commit('SET_FAILED_MESSAGE', err.response);
      } else {
        commit('SET_FAILED');
      }
    }
  },
  resetSearch({ commit }) {
    commit('resetState');
  },
};

const mutations = {
  SET_FAILED(state) {
    state.message = 'Network Problem';
    state.status = 0;
  },

  SET_FAILED_MESSAGE(state, error) {
    if (error.status == 400) {
      state.message = 'Email or Password Invalid';
      state.status = 0;
    } else {
      state.message = 'Network Problem';
      state.status = 0;
    }
  },
  SET_FAILED_LOGIN(state, error) {
    state.message = error.response.data.error;
    state.status = 0;
  },
  SET_FAILED_REGISTER(state) {
    state.message = 'Gagal Register';
    state.status = 0;
  },
  SET_USER(state, response) {
    state.role = response.role_type;
    state.user = response;
  },
  SET_LOGIN_DATA(state, response) {
    state.role = response.role;
    const key = makeid();
    state.token = response.token;
    localStorage.setItem('uajyKey', key);
  },
  SET_RESPONSE(state, response) {
    state.status = response.status;
    state.message = response.message;
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
