import { getData } from '@/store/config/method';

const initialState = () => ({
  hazards: [],
  status: null,
  message: null,
  request: { ...requestInit },
});
const requestInit = {
  page: 1,
  show: 10,
  sortBy: null,
  sorting: null,
  search: null,
};
const state = initialState;

const getters = {
  getHazards: (state) => state.hazards,
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
  getRequest: (state) => state.request,
};

const actions = {
  async setHazardsData({ commit }) {
    try {
      const response = await getData('api', 'v1/public/hazards');
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  resetSearch({ commit }) {
    commit('resetState');
  },
};

const mutations = {
  SET_RESPONSE(state, response) {
    state.status = response.data.status;
    state.message = response.data.msg;
  },
  SET_FAILED(state) {
    state.message = 'Network Problem';
    state.status = 0;
  },

  SET_FAILED_MESSAGE(state, error) {
    if (error.status == 400) {
      state.message = 'Nomor KTP atau NIK Anda Salah';
      state.status = 0;
    } else {
      state.message = 'Network Problem';
      state.status = 0;
    }
  },
  SET_FAILED_LOGIN(state) {
    state.message = 'Nomor KTP atau NIK Anda Salah';
    state.status = 0;
  },
  SET_CONTENT_DATA(state, response) {
    state.hazards = response;
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
