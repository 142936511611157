
import Vue from 'vue';
import App from "./App.vue";
import "./registerServiceWorker";
import router from './router';
import store from './store';
import style from "./style/index.scss";

//Vue Chat Scroll
import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll)

//Vue Good Table
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css' // import the styles
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.use(VueGoodTablePlugin);
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false;
import { i18n } from './i18n'
import { Trans } from '@/plugins/translation'
Vue.prototype.$translate = Trans.i18nRoute.bind()
const startApp = async (r) => {
  const router = await r
  Vue.config.productionTip = false
  new Vue({
    router,
    store,
    style,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}
startApp(router)
