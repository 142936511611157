import { postData, getData, putData, destroyData, getDataWithParams } from '@/store/config/method';

const initialState = () => ({
  content: [],
  status: 0,
  message: null,
  request: { ...requestInit },
  event: {},
  meta: {}
});
const requestInit = {
  page: 1,
  show: 10,
  sortBy: null,
  sorting: null,
  search: null,
};
const state = initialState;

const getters = {
  getEvent: (state) => state.content,
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
  getRequest: (state) => state.request,
  getEventData: (state) => state.event,
  getMeta: (state) => state.meta
};

const actions = {
  async setEventData({ commit, rootState, state }) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
        params: state.request,
      };
      const response = await getData('api', 'v1/events', option);
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async setEventSingleData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData('api', `v1/events/${payload}`, option);
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async setContentPublicData({ commit }, params) {
    try {
      const response = await getDataWithParams('api', 'v1/public/events', params);
      commit('SET_CONTENT_DATA', response.data.data.data);
      commit('SET_META_DATA', response.data.data)
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async setSinglePublicData({ commit }, id) {
    try {
      const response = await getData('api', `v1/public/events/${id}`);
      commit('SET_CONTENT_EVENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async postEventData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData('api', 'v1/events', payload, option);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async updateEventData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData(
        'api',
        `v1/events/${payload.get('id')}`,
        payload,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async onVerify({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await putData(
        'api',
        `v1/events/${payload.id}/${payload.action}`,
        null,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async onDelete({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await destroyData('api', `v1/events/${payload}`, option);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },

  resetSearch({ commit }) {
    commit('resetState');
  },
};

const mutations = {
  SET_RESPONSE(state, response) {
    state.status = response.data.status;
    state.message = response.data.msg;
  },
  SET_FAILED(state) {
    state.message = 'Network Problem';
    state.status = 0;
  },

  SET_FAILED_MESSAGE(state, error) {
    if (error.status == 400) {
      state.message = 'Nomor KTP atau NIK Anda Salah';
      state.status = 0;
    } else {
      state.message = 'Network Problem';
      state.status = 0;
    }
  },
  SET_FAILED_LOGIN(state) {
    state.message = 'Nomor KTP atau NIK Anda Salah';
    state.status = 0;
  },
  SET_CONTENT_DATA(state, response) {
    state.content = response;
    //add property open dropdown
    state.content.forEach(function (element) {
      element.open_dropdown = 'false';
    });
  },
  SET_META_DATA(state, response) {
    state.meta = response;
  },
  SET_CONTENT_EVENT_DATA(state, response) {
    state.event = response
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
