<template>
  <div class="s-input-fieldset s-mr-10">
    <div class="s-input-label s-mb-5" :class="error ? 's-text-error' : ''">
      {{ label }}<span v-if="star" class="s-text-error">*</span>
    </div>
    <fieldset :class="`${rightIcon? 's-center-flex':''} ${error ? 's-input-box-error' : ''}` ">
      <input
        :type="type"
        :value="value"
        :min="min"
        :max="max"
        @input="onChange($event)"
        :name="label"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxlength"
        :minlength="minlength"
        @change="change"
        @focus="onFocus"
        @blur="onBlur"
        @keypress="onKeypress"
        @keydown="onKeydown"
        @keyup="onKeyup"
        @keyup.enter="onKeyupEnter"
        @keypress.enter="onKeypressEnter"
        @keyup.tab="onKeyupTab"
        @keydown.tab="onKeydownTab"
        :readonly="readonly"
        :class="clickable? 's-cursor-pointer':''"
      />
      <div v-if="error" class="s-input-icon-error">
        <img :src="require('@/assets/icons/error/icon-alert-circle.svg')" />
      </div>
      <div v-if="rightIcon" class="s-pr-10">
        <slot name="rightIcon"></slot>
      </div>
    </fieldset>
    <div class="s-flex">
      <div v-if="error" class="s-input-error">
        {{ $t(errorMsg) }}
      </div>
      <div v-else class="i-p-hint">
        {{ hint }}
      </div>
      <div class="s-ml-auto">
        <slot name="rightBottomField"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      default: 'text',
    },
    value: {
      required: true,
    },
    label: {
      default: '',
    },
    min: {
      default: '',
    },
     max: {
      default: '',
    },
    error: {
      default: false,
    },
    errorMsg: {
      default: "general.fillThisField",
    },
    star: {
      default: false,
    },
    maxlength: {
      default: 255,
    },
    minlength: {
      default: 0,
    },
    rightIcon: {
      default: false,
    },
    readonly: {
      default: false,
    },
    placeholder: {
      default: '',
    },
    hint: {
      default: '',
    },
    disabled: {
      default: false,
    },
    clickable: {
      default: false
    }
  },
  methods: {
    onFocus(e) {
      this.$emit('onFocus', e);
    },
    onChange(e) {
      const value = e.target.value;
      this.$emit('input', value);
    },
    change(e) {
      this.$emit('change', e);
    },
    onBlur(e) {
      this.$emit('blur', e);
    },
    onKeypress(e) {
      this.$emit('keypress', e);
    },
    onKeydown(e) {
      this.$emit('keydown', e);
    },
    onKeyup(e) {
      this.$emit('keyup', e);
    },
    onKeyupEnter(e) {
      this.$emit('keyup.enter', e);
    },
    onKeypressEnter(e) {
      this.$emit('keypress.enter', e);
    },
    onKeyupTab(e) {
      this.$emit('keyup.tab', e);
    },
    onKeydownTab(e) {
      this.$emit('keydown.tab', e);
    },
    onClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>
