<template>
  <div>
    <p class="s-text-primary s-weight-600">{{ title }}</p>

    <div class="s-pb-30 s-position-relative">
      <div v-if="!fileDoc.name" class="s-file-upload s-text-center">
        <div>
          <img :src="require('@/assets/icons/primary/cloud_upload.png')" />
          <p>{{$t('general.dragAndDrop')}}</p>
          <p>{{$t('general.or')}}</p>
          <Button>{{$t('general.uploadFile')}}</Button>
        </div>
      </div>
      <input
        v-if="!fileDoc.name"
        id="document"
        ref="document"
        class="s-hide"
        type="file"
        @change="importData('document')"
        multiple
      />
      <div class="s-position-relative s-fullWidth s-center-flex">
        <div
          class="
            s-center-flex s-mb-16 s-bg-green-40 s-plr-10 s-radius-10 s-fullWidth
          "
          v-if="fileDoc.name"
        >
          <p class="s-text-primary">{{ fileDoc.name }}</p>
          <img
            :src="require('@/assets/icons/close-primary.svg')"
            class="s-ml-auto"
            @click="fileDoc = {}"
            style="cursor: pointer"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import form from "@/mixins/form";
export default {
  mixins: [form],
  props: {
    title: {
      default: "",
    },
    fileDoc: {
      default: {},
    },
  },
  data: () => ({
    hideSnackbarTime: null,
  }),
  methods: {
    importData(id) {
      const fileInput = this.$refs[id];
      const fileUpload = fileInput.files[0];
      const fsize = fileUpload.size;
      const file = Math.round(fsize / 1024);

      if (file <= 5000) this.fileDoc = fileUpload;
    },
  },
};
</script>
