<template>
  <div  v-if="showLoading" class="loading">
    <img
      class="s-mlr-auto"
      :src="require('@/assets/loading-infinite-large.svg')"
      alt="loading"
    />
  </div>
</template>
<script>
import form from "@/mixins/form";
export default {
  mixins: [form],
  props: {
    showLoading: {
      default: false,
    },
  },
  data: () => ({
    hideSnackbarTime: null,
  }),
};
</script>
<style scoped>
.loading {
  position: fixed;
  z-index: 10000000;
  background: #0a0a0b87;
  padding: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
}
</style>