
import publicRoute from './public/publicRoute';
import dashboardRoute from './dashboard/dashboardRoute';
import { routerFilter } from '@/plugins/routes';
import { loadView, loadMain} from '@/router/lazyLoad';
const generateRoutes = async () => {
  const publicRoutes = await publicRoute;
  const dashboardRoutes = await dashboardRoute;
  const routes = [
    {
      path: "/:locale(en)?/",
      meta: {
        requiresAuth: false,
        roleVerif: false,
        role: [],
      },
      component: loadView("Home"),
      children: [
        ...publicRoutes, 
        
      ]
    },

    {
      path: "/:locale(en)?/",
      meta: {
        requiresAuth: false,
        roleVerif: false,
        role: [],
      },
      component: loadView("Dashboard"),
      children: [
        ...dashboardRoutes,
      ]
    },
 
    {
        path: '/:authType(login|create-account|forgot-password|change-password)?',
        name: 'authentication',
        meta: {
          requiresAuth: false,
          roleVerif: false,
          role: []
        },
        component: loadMain('authentication'),
    },
    {
      path: "/:locale(en)?/*",
      name: "NotFound",
      meta: {
        requiresAuth: false,
        roleVerif: false,
        role: [],
      },
      component: loadView("Notfound"),
    },
  ];

  return await routerFilter(routes);
};
export default generateRoutes();