
import store from "@/store"
import localForage from 'localforage';
import { Trans } from './translation'

const routerFilter = async (routes) => {
    const temp = await localForage.getItem("auth")
    let myrole = null
    if(temp){
      myrole = temp?.auth?.role
    }
    const isLoggedIn = store.getters['getIsLoggedIn']
    const tempRoute = routes.filter((row) => {
      return (row.meta.requiresAuth == isLoggedIn && ((row.meta.roleVerif && row.meta.role.some(role => role == myrole) || !row.meta.roleVerif))) || (!row.meta.requiresAuth && row.meta.beforelogin != isLoggedIn)
    })
    return tempRoute
  }
const routeMiddleware = async (to, from, next) =>{
    await store.restored
    const lang = to.params.locale == 'en' ? 'en': 'id'
    await store.dispatch('locale/setCurrentLocale', lang)
    let locale = store.getters['locale/getCurrentLocale']
    if(!Trans.isLocaleSupported(locale)) locale = Trans.defaultLocale
    return Trans.changeLocale(locale).then(() => 
        validateUser(to, next)
    )
  }
const validateUser = (to, next) => {
    if (to.path == '/logout' && !store.getters['getIsLoggedIn']) {
        return next({
          name: 'Login'
        })
    }
    if (to.matched.some(route => route.meta.beforelogin) && store.getters['getIsLoggedIn']) {
        return next({
            name: 'Dashboard'
        })
    }
    next()
  }
  
  export {
    routerFilter,
    validateUser,
    routeMiddleware
  }