import {
  postData,
  getData,
  putData,
  downloadFile,
  destroyData,
} from '@/store/config/method';

const initialState = () => ({
  actionplan: [],
  files: [],
  status: null,
  message: null,
  blobData: null,
  forum: [],
  request: { ...requestInit },
});
const requestInit = {
  page: 1,
  show: 10,
  sortBy: null,
  sorting: null,
  search: null,
};
const state = initialState;

const getters = {
  getActionPlan: (state) => state.actionplan,
  getForum: (state) => state.forum,
  getFiles: (state) => state.files,
  getBlobData: (state) => state.blobData,
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
  getRequest: (state) => state.request,
};

const actions = {
  resetRequest({ commit }) {
    commit('SET_REQUEST', { ...requestInit });
  },
  async setActionPlanData({ commit, rootState, state }) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
        params: state.request,
      };
      const response = await getData('api', 'v1/assessments', option);
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async setActionPlanSingleData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData(
        'api',
        `v1/assessments/${payload}`,
        option
      );
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  
  // async setActionPlanDataPublicData({ commit }) {
  //   try {
  //     const response = await getData('api', 'v1/public/assessments');
  //     commit('SET_CONTENT_DATA', response.data.data.data);
  //     commit('SET_RESPONSE', response);
  //   } catch (_) {
  //     commit('SET_FAILED_LOGIN');
  //   }
  // },
  async postActionPlanData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData('api', 'v1/assessments', payload, option);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async onFinishTask({ commit, rootState }, payload) {
    
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData('api', `v1/assessments/${payload.get('id')}/${payload.get('type')}`, payload, option);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async startActionPlan({ commit, rootState }, payload) {
    
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await putData('api', `v1/assessments/${payload.id}/start`, payload, option);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async putActionPlanData({ commit, rootState }, payload) {
    
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await putData(
        'api',
        `v1/assessments/${payload.id}`,
        payload,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },

  async onVerify({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await putData(
        'api',
        `v1/assessments/${payload.id}/${payload.action}`,
        payload,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },

  async setFiles({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData(
        'api',
        `v1/assessments/${payload}/files`,
        option
      );
      commit('SET_FILE_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },

  async downloadFileAction({ commit }, { url, type }) {
    try {
      var response = await downloadFile(url);
      commit('SET_BLOBDATA', { value: response.data, type: type });
    } catch (e) {
      if (e.response) {
        commit('SET_FAILED_MESSAGE', e.response);
      } else {
        commit('SET_FAILED');
      }
    }
  },

  async setForumData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData(
        'api',
        `v1/chats/${payload}/messages`,
        option
      );
      commit('SET_FORUM_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async postForumData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData(
        'api',
        `v1/chats/${payload.room_id}/messages`,
        payload,
        option
      );
      commit('SET_FORUM_DATA', response.data.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async onPublish({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData(
        'api',
        `v1/assessments/${payload}/publish`,
        null,
        option
      );
      commit('SET_FORUM_DATA', response.data.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async onDelete({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await destroyData(
        'api',
        `v1/assessments/${payload}`,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  resetSearch({ commit }) {
    commit('resetState');
  },
};

const mutations = {
  SET_RESPONSE(state, response) {
    state.status = response.data.status;
    state.message = response.data.msg;
  },
  SET_FAILED(state) {
    state.message = 'Network Problem';
    state.status = 0;
  },
  SET_FAILED_MESSAGE(state, error) {
    state.message = error?.data?.msg || 'Network Problem';
    state.status = error?.data?.status || 0;
  },
  SET_FAILED_LOGIN(state) {
    state.message = 'Nomor KTP atau NIK Anda Salah';
    state.status = 0;
  },
  SET_CONTENT_DATA(state, response) {
    state.actionplan = response;
    //add property open dropdown
    state.actionplan.forEach(function (element) {
      element.open_dropdown = 'false';
    });
  },
  SET_FORUM_DATA(state, response) {
    state.forum = response;
  },
  SET_FILE_DATA(state, response) {
    state.files = response;
    //add property open dropdown
    state.files.forEach(function (element) {
      element.open_dropdown = 'false';
    });
  },
  SET_BLOBDATA(state, { value, type }) {
    state.blobData = new Blob([value], { type: type });
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },

  SET_REQUEST(state, value) {
    state.request = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
