import { postData, getData, putData } from '@/store/config/method';

const initialState = () => ({
  user: [],
  status: null,
  message: null,
  request: { ...requestInit },
});
const requestInit = {
  page: 1,
  show: 10,
  sortBy: null,
  sorting: null,
  search: null,
};
const state = initialState;

const getters = {
  getUser: (state) => state.user,
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
  getRequest: (state) => state.request,
};

const actions = {
  async changeStatus({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await putData(
        'api',
        `v1/users/${payload}/change-status`,
        null,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async changePassword({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await putData(
        'api',
        `v1/users/change-password`,
        payload,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (err) {
      if (err.response) {
        commit('SET_FAILED_MESSAGE', err.response);
      } else {
        commit('SET_FAILED')
      }
    }
  },
};

const mutations = {
  SET_RESPONSE(state, response) {
    state.status = response.data.status;
    state.message = response.data.msg;
  },
  SET_FAILED(state) {
    state.message = 'Network Problem';
    state.status = 0;
  },

  SET_FAILED_MESSAGE(state, error) {
    if (error.status == 400) {
      state.message = 'Your current password is wrong!';
      state.status = 0;
    } else {
      state.message = 'Network Problem';
      state.status = 0;
    }
  },

  SET_CONTENT_DATA(state, response) {
    state.user = response;
    //add property open dropdown
    state.user.forEach(function (element) {
      element.open_dropdown = 'false';
    });
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
