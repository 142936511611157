
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { routeMiddleware } from '@/plugins/routes'

const setRouter = async (routes) => {
  const data = await routes
  Vue.use(VueRouter)
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: data
  })
  router.beforeEach(routeMiddleware)
  return router
}
export default setRouter(routes)

