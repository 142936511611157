import { postData, getData, putData, destroyData } from '@/store/config/method';

const initialState = () => ({
  expertise: [],
  status: null,
  message: null,
  request: { ...requestInit },

});
const requestInit = {
  page: 1,
  show: 10,
  sortBy: null,
  sorting: null,
  search: null,
};

const state = initialState;

const getters = {
  getExpertise: (state) => state.expertise,
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
  getRequest: (state) => state.request,
};

const actions = {
  async setExpertiseDropdownData({ commit, rootState, state}, params) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
        params: state.request,
      };
      const response = await getData('api', 'v1/public/expertise-details-dropdown', option);
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async setExpertiseData({ commit, rootState }, params) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData('api', 'v1/expertises', option);
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },

  async setExpertiseSingleData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData('api', `v1/expertises/${payload}`, option);
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },

  async postExpertiseData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData('api', 'v1/expertises', payload, option);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },

  async putExpertiseData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await putData('api', `v1/expertises/${payload.id}`, payload, option);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async deleteExpertiseData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await destroyData('api', `v1/expertises/${payload}`, option);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async setExpertisePublicData({commit}) {
    try {
      const response = await getData('api', 'v1/public/expertise-details-dropdown');
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  resetSearch({ commit }) {
    commit('resetState');
  },
};

const mutations = {
  SET_RESPONSE(state, response) {
    state.status = response.data.status;
    state.message = response.data.msg;
  },
  SET_FAILED(state) {
    state.message = 'Network Problem';
    state.status = 0;
  },

  SET_FAILED_MESSAGE(state, error) {
    if (error.status == 400) {
      state.message = 'Nomor KTP atau NIK Anda Salah';
      state.status = 0;
    } else {
      state.message = 'Network Problem';
      state.status = 0;
    }
  },
  SET_FAILED_LOGIN(state) {
    state.message = 'Nomor KTP atau NIK Anda Salah';
    state.status = 0;
  },
  SET_CONTENT_DATA(state, response) {
    state.expertise = response;
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
