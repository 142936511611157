
import Vue from "vue";
import Vuex from "vuex";
import modules from './modules';
import VuexPersistence from "vuex-persist";
import localForage from "localforage";

localForage.config({
  name: "web-borobudur-uajy",
  version: 1.0,
  storeName: "uajy x borobudur", // Should be alphanumeric, with underscores.
});
const authStorage = new VuexPersistence({
  key: "auth",
  storage: localForage,
  reducer: (state) => ({ auth: state.auth }),
  asyncStorage: true,
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: !!localStorage.getItem("uajyKey"),
    isMobile: false,
    collapse: true,
    modal: false,
    loadingPage: false,
    snackbar: {
      type: "success",
      text: "message",
      withclose: true,
      show: false
    },
  },
  getters: {
    getIsMobile: (state) => state.isMobile,
    getIsLoggedIn: (state) => state.isLoggedIn,
    getModal: (state) => state.modal,
    getLoadingPage: (state) => state.loadingPage,
    getCollapse: state => state.collapse,
    getSnackbar: state => state.snackbar,
  },
  actions: {
    setIsMobile({ state }, value) {
      state.isMobile = value < 1000;
    },

    setLoadingPage({ commit }, value) {
      commit('SET_LOADING_PAGE', value)
    },

    setCollapse({commit}, value) {
      commit('SET_COLLAPSE', value);
    },

    setModal({ state }, value) {
      state.modal = value;
    },

    async logoutStorage({ commit, state }) {
      localStorage.clear();
      state.isLoggedIn = false;
      const initial = modules;
      Object.keys(initial).forEach((key) => commit(`${key}/resetState`));
    },
    showSnackbar({ commit }, {
      type = "success",
      text = "message",
      withclose = true,
      show = true
    }) {
      var value = {
        type: type,
        text: text,
        withclose: withclose,
        show: show
      }
      commit('SET_SNACKBAR', value);
    },
    hideSnackbar({ commit }) {
      commit('HIDE_SNACKBAR');
    },
  },
  mutations: {
    SET_COLLAPSE(state, value) {
      state.collapse = value;
    },
    RESET_COLLAPSE(state) {
      state.collapse = false;
    },
    SET_LOGIN(state) {
      state.isLoggedIn = true
    },
    SET_LOADING_PAGE(state, value) {
      state.loadingPage = value;
    },
    SET_SNACKBAR(state, value) {
      state.snackbar = value;
    },
    HIDE_SNACKBAR(state) {
      state.snackbar.show = false;
    },
  },
  modules: {
    ...modules,
  },
  plugins: [authStorage.plugin],
});
