<template>
  <div id="app">
    <main>
      <router-view />
      <Snackbar />
       <LoadingPage :showLoading='loadingPage'/>
    </main>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Snackbar from '@/components/helper/Snackbar';
import LoadingPage from '@/components/helper/Loading'

export default {
  components: { Snackbar, LoadingPage},
  mounted() {
    this.initData();
  },
  computed: {
    ...mapGetters({
      isMobile: 'getIsMobile',
      loadingPage: 'getLoadingPage',
    }),
  },
  methods: {
    ...mapActions({
      setIsMobile: 'setIsMobile',
    }),
    initData() {
      this.setIsMobile(window.innerWidth);
    },
  },
};
</script>
