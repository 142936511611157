import { routerFilter } from '@/plugins/routes';
import { loadDashboard } from '@/router/lazyLoad';

const routes = [
  {
    path: 'dashboard',
    name: 'Dashboard CMS',
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('landingDashboard'),
  },
  //BRRD
  {
    path: 'BRRD',
    name: 'BRRD',
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('BRRD'),
  },
  {
    path: 'BRRDFile/:id',
    name: 'BRRD File',
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('BRRDFile'),
  },
  {
    path: '/BRRDFile/:inputType/:id',
    name: 'New BRRD File',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('BRRDFileNew'),
  },
  {
    path: 'BRRD/detail/:slug',
    name: 'BRRD Detail',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('BRRDDetail'),
  },
  {
    path: 'BRRD/:inputType/:slug',
    name: 'Edit BRRD',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('BRRDNew'),
  },
  {
    path: 'BRRD/:inputType',
    name: 'New BRRD',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('BRRDNew'),
  },

  //Crowdsourcing
  {
    path: 'CFC',
    name: 'CFC',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('CFC'),
  },
  {
    path: 'CFC/detail/:id',
    name: 'CFC Detail',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('CFCDetail'),
  },
  {
    path: 'CFC/comment/:id',
    name: 'CFC Comment',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('CFCComment'),
  },

  //Educational Content
  {
    path: 'edc-content',
    name: 'Edc Content',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('edcContent'),
  },
  {
    path: 'edc-content/:inputType',
    name: 'New Edc Content',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('edcContentNew'),
  },
  {
    path: 'edc-content/detail/:slug',
    name: 'Edc Content Detail',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('edcContentDetail'),
  },
  {
    path: 'edc-content/:inputType/:slug',
    name: 'Edit Edc Content',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('edcContentNew'),
  },

  //Event Publication
  {
    path: 'event-publication',
    name: 'Event Publication',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('eventPublication'),
  },
  {
    path: 'event-publication/:inputType',
    name: 'New Event Publication',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('eventPublicationNew'),
  },
  {
    path: 'event-publication/:inputType/:id',
    name: 'Edit Event Publication',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('eventPublicationNew'),
  },
  {
    path: 'event-publication-detail/:id',
    name: 'Event Publication Detail',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('eventPublicationDetail'),
  },

  //APMA
  {
    path: 'APMA',
    name: 'APMA',
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('APMA'),
  },
  {
    path: '/APMAFile/:id',
    name: 'APMA File',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('APMAFile'),
  },
  {
    path: 'APMAFile/:inputType/:id',
    name: 'New APMA File',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('APMAFileNew'),
  },
  {
    path: 'APMA/assign-task/:inputType/:id',
    name: 'Edit APMA',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('APMAAssignTaskNew'),
  },
  {
    path: 'APMA/assign-task/:inputType',
    name: 'New APMA Assign Task',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('APMAAssignTaskNew'),
  },
  {
    path: 'APMA/assign-task-detail/:id',
    name: 'Detail APMA Assign Task',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('APMAAssignTaskDetail'),
  },
  {
    path: 'APMA/detail/:id',
    name: 'Detail APMA',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('APMADetail'),
  },

  {
    path: 'APMA/finish-task/:inputType/:id',
    name: 'APMA Finish Task',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('APMAFinishTask'),
  },

  {
    path: 'APMA/comment/:room_id',
    name: 'APMA Comment',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('APMAComment'),
  },

  //Data Master
  {
    path: 'users/:userType/:inputType',
    name: 'New Users',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('usersNew'),
  },
  {
    path: 'users/:userType/:inputType/:id',
    name: 'Edit Users',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('usersNew'),
  },
  {
    path: 'stakeholders',
    name: 'Stakeholders',
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('stakeholders'),
  },

  {
    path: 'experts',
    name: 'Experts',
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('experts'),
  },

  {
    path: 'public-users',
    name: 'Public Users',
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('publicUsers'),
  },

  {
    path: 'admins',
    name: 'Admins',
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('admins'),
  },

  //Expert Matching
  {
    path: 'expert-matching-dashboard',
    name: 'Expert Matching Dashboard',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('expertMatching'),
  },
  {
    path: 'expert-matching/post-request/:inputType',
    name: 'New Expert Matching',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('expertMatchingNew'),
  },
  {
    path: 'expert-matching/post-request/:inputType/:id',
    name: 'Edit Expert Matching',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('expertMatchingNew'),
  },
  {
    path: 'expert-matching-list',
    name: 'Expert Matching List',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('expertMatchingList'),
  },
  {
    path: 'expert-matching/detail/:id',
    name: 'Expert Matching Detail',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('expertMatchingDetail'),
  },

  {
    path: 'expert-matching/signed-up',
    name: 'Expert Matching Signed Up',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('expertMatchingSignedUp'),
  },

  {
    path: 'expert-matching/rating/:inputType/:id',
    name: 'New Expert Matching Rating',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('expertMatchingRatingNew'),
  },

  {
    path: 'expert-matching/expert/list/:id',
    name: 'Expert Matching Expert List',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('expertMatchingExpertList'),
  },

  {
    path: 'expert-matching/upload-files/new/:id',
    name: 'New Expert Matching Upload Files',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('expertMatchingUploadFilesNew'),
  },

  {
    path: 'expert-matching/upload-files/:id',
    name: 'Expert Matching Upload Files',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('expertMatchingUploadFiles'),
  },

  //Admin
  {
    path: 'admin',
    name: 'Admin',
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('superAdmin/admin'),
  },

  //Expert Request
  {
    path: 'expert-request/new',
    name: 'New Expert Request',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('expertRequestNew'),
  },

  {
    path: 'expert-request',
    name: 'Expert Request',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('expertRequest'),
  },

  //Expertise
  {
    path: 'expertise',
    name: 'Expertise',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('expertise'),
  },

  {
    path: 'expertiseDetail/:id',
    name: 'Expertise Detail',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('expertiseDetail'),
  },
  //Change password
  {
    path: 'change-password-dashboard',
    name: 'Change Password',
    props: true,
    meta: {
      requiresAuth: false,
      roleVerif: false,
      role: [],
    },
    component: loadDashboard('changePasswordDashboard'),
  },

];
export default routerFilter(routes);
