import {
  getData,
  putData,
  destroyData,
  postData
} from '@/store/config/method';

const initialState = () => ({
  feedback: [],
  files: [],
  status: null,
  message: null,
  blobData: null,
  forum: [],
  reply: [],
  request: { ...requestInit },
  replies: {}
});
const requestInit = {
  page: 1,
  show: 10,
  sortBy: null,
  sorting: null,
  search: null,
};
const state = initialState;

const getters = {
  getFeedback: (state) => state.feedback,
  getReply: (state) => state.reply,
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
  getRequest: (state) => state.request,
  getReplies: (state) => state.replies || {}
};

const actions = {
  resetRequest({ commit }) {
    commit('SET_REQUEST', { ...requestInit });
  },
  async setFeedbackData({ commit, rootState, state }) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
        params: state.request,
      };
      const response = await getData('api', 'v1/feedbacks', option);
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async setFeedbackSingleData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData('api', `v1/feedbacks/${payload}`, option);
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async setFeedbackPublicData({ commit }) {
    try {
      const response = await getData('api', 'v1/public/feedbacks')
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },

  async setFeedbackReply({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData('api', `v1/feedbacks/${payload}/responses`, option);
      commit('SET_REPLY_DATA', response.data.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async setFeedbackPublicReply({ commit }, id) {
    try {
      const response = await getData('api', `v1/public/feedbacks/${id}/responses`);
      commit('SET_REPLY_DATA', response.data.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },

  async postFeedbackReply({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData(
        'api',
        `v1/feedback-responses`,
        payload,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },

  async putFeedbackData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await putData(
        'api',
        `v1/feedbacks/${payload.id}`,
        payload,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },

  async onDelete({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await destroyData(
        'api',
        `v1/feedbacks/${payload}`,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  setReplies({commit}, value){
      commit('SET_REPLIES', value)
  },

  async onDeleteReply({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await destroyData(
        'api',
        `v1/feedback-responses/${payload}`,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },

  resetSearch({ commit }) {
    commit('resetState');
  },
};

const mutations = {
  SET_RESPONSE(state, response) {
    state.status = response.data.status;
    state.message = response.data.msg;
  },
  SET_FAILED(state) {
    state.message = 'Network Problem';
    state.status = 0;
  },
  SET_FAILED_MESSAGE(state, error) {
    state.message = error?.data?.msg || 'Network Problem';
    state.status = error?.data?.status || 0;
  },
  SET_FAILED_LOGIN(state) {
    state.message = 'Nomor KTP atau NIK Anda Salah';
    state.status = 0;
  },
  SET_CONTENT_DATA(state, response) {
    state.feedback = response;
    //add property open dropdown
    state.feedback.forEach(function (element) {
      element.open_dropdown = 'false';
    });
  },
  SET_REPLY_DATA(state, response) {
    state.reply = response
  },
  SET_FILE_DATA(state, response) {
    state.files = response;
    //add property open dropdown
    state.files.forEach(function (element) {
      element.open_dropdown = 'false';
    });
  },
  SET_REPLIES(state, value) {
      state.replies = value
  },
  SET_BLOBDATA(state, { value, type }) {
    state.blobData = new Blob([value], { type: type });
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },

  SET_REQUEST(state, value) {
    state.request = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
