import { postData, getData, putData, destroyData, downloadFile } from '@/store/config/method';

const initialState = () => ({
  content: [],
  status: null,
  message: null,
  blobData: null,
  request: { ...requestInit },
});
const requestInit = {
  page: 1,
  show: 10,
  sortBy: null,
  sorting: null,
  search: null,
};
const state = initialState;

const getters = {
  getContent: (state) => state.content,
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
  getRequest: (state) => state.request,
  getBlobData: (state) => state.blobData,

};

const actions = {
  resetRequest({ commit }) {
    commit('SET_REQUEST', { ...requestInit });
  },

  async setContentData({ commit, rootState, state }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
        params: state.request,
      };
      // const response = await getData('api', 'v1/brrds', option);
      const response = await getData('api', `v1/brrd/${payload}/details`, option);
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  async setContentSingleData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData('api', `v1/brrd-details/${payload}`, option);
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async setContentPublicData({ commit }) {
    try {
      const response = await getData('api', 'v1/public/brrds');
      commit('SET_CONTENT_DATA', response.data.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async postContentData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData('api', 'v1/brrd-details', payload, option);
      commit('SET_RESPONSE', response);
    } catch (e) {
      commit('SET_RESPONSE', e.response);
      commit('SET_FAILED');
    }
  },
  async updateContentData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData(
        'api',
        `v1/brrd-details/${payload.get('id')}`,
        payload,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },

  async onVerify({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await putData(
        'api',
        `v1/brrds/${payload.id}/${payload.action}`,
        null,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },
  async onDelete({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await destroyData('api', `v1/brrd-details/${payload}`, option);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED');
    }
  },

  async downloadFileAction({ commit }, { url, type }) {
    try {
      var response = await downloadFile(url);
      commit('SET_BLOBDATA', { value: response.data, type: type });
    } catch (e) {
      if (e.response) {
        commit('SET_FAILED_MESSAGE', e.response);
      } else {
        commit('SET_FAILED');
      }
    }
  },

  resetSearch({ commit }) {
    commit('resetState');
  },
};

const mutations = {
  SET_RESPONSE(state, response) {

    state.status = response.data.status;
    state.message = response.data.msg;
  },
  SET_FAILED(state) {
    state.message = 'Network Problem';
    state.status = 0;
  },
SET_BLOBDATA(state, { value, type }) {
    state.blobData = new Blob([value], { type: type });
  },
  SET_FAILED_MESSAGE(state, error) {
    if (error.status == 400) {
      state.message = 'Nomor KTP atau NIK Anda Salah';
      state.status = 0;
    } else {
      state.message = 'Network Problem';
      state.status = 0;
    }
  },
  SET_FAILED_LOGIN(state) {
    state.message = 'Nomor KTP atau NIK Anda Salah';
    state.status = 0;
  },
  SET_CONTENT_DATA(state, response) {
    state.content = response;
    //add property open dropdown
    state.content.forEach(function (element) {
      element.open_dropdown = 'false';
      //element.youtube_link =  `https://www.youtube.com/embed/${element.youtube_link}`
    });
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
  SET_REQUEST(state, value) {
    state.request = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
