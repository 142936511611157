import {getData, putData, downloadFile } from '@/store/config/method';

const initialState = () => ({
  expert: [],
  status: null,
  message: null,
  blobData: null,
  request: { ...requestInit },
});
const requestInit = {
  page: 1,
  show: 10,
  sortBy: null,
  sorting: null,
  search: null,
};
const state = initialState;

const getters = {
  getExpert: (state) => state.expert,
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
  getRequest: (state) => state.request,
  getBlobData: (state) => state.blobData,
};

const actions = {
  async downloadFileAction({ commit }, { url, type }) {
    try {
      var response = await downloadFile(url);
      commit('SET_BLOBDATA', { value: response.data, type: type });
    } catch (e) {
      if (e.response) {
        commit('SET_FAILED_MESSAGE', e.response);
      } else {
        commit('SET_FAILED');
      }
    }
  },
  async setExpertData({ commit, rootState, state}) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
        params: state.request,
      };
      const response = await getData('api', 'v1/experts-pendings', option);
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
  
  async putExpertData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await putData(
        'api',
        `v1/experts/${payload.id}/${payload.action.toLowerCase()}`,
        null,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (_) {
      commit('SET_FAILED_LOGIN');
    }
  },
 

  resetSearch({ commit }) {
    commit('resetState');
  },
};

const mutations = {
  SET_RESPONSE(state, response) {
    state.status = response.data.status;
    state.message = response.data.msg;
  },
  SET_FAILED(state) {
    state.message = 'Network Problem';
    state.status = 0;
  },

  SET_FAILED_MESSAGE(state, error) {
    if (error.status == 400) {
      state.message = 'Nomor KTP atau NIK Anda Salah';
      state.status = 0;
    } else {
      state.message = 'Network Problem';
      state.status = 0;
    }
  },
  SET_FAILED_LOGIN(state) {
    state.message = 'Nomor KTP atau NIK Anda Salah';
    state.status = 0;
  },
  SET_CONTENT_DATA(state, response) {
    state.expert = response;
    //add property open dropdown
    state.expert.forEach(function (element) {
      element.open_dropdown = 'false';
    });
  },
  SET_BLOBDATA(state, { value, type }) {
    state.blobData = new Blob([value], { type: type });
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
