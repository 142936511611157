import { postData, getData, putData } from '@/store/config/method';

const initialState = () => ({
  stakeholder: [],
  status: null,
  message: null,
  request: { ...requestInit },
});
const requestInit = {
  page: 1,
  show: 10,
  sortBy: null,
  sorting: null,
  search: null,
};
const state = initialState;

const getters = {
  getStakeholder: (state) => state.stakeholder,
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
  getRequest: (state) => state.request,
};

const actions = {
  async setStakeholderData({ commit, rootState, state }) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
        params: state.request,
      };
      const response = await getData('api', 'v1/stakeholders', option);
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);

    } catch (err) {
      if (err.response) {
        commit('SET_FAILED_MESSAGE', err.response);
      } else {
        commit('SET_FAILED')
      }
    }
  },
  async setStakeholderSingleData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData(
        'api',
        `v1/stakeholders/${payload}`,
        option
      );
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (err) {
      if (err.response) {
        commit('SET_FAILED_MESSAGE', err.response);
      } else {
        commit('SET_FAILED')
      }
    }
  },
  async setStakeholderDropdown({ commit, rootState }) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await getData('api', 'v1/stakeholders-dropdown', option);
      commit('SET_CONTENT_DATA', response.data.data);
      commit('SET_RESPONSE', response);
    } catch (err) {
      if (err.response) {
        commit('SET_FAILED_MESSAGE', err.response);
      } else {
        commit('SET_FAILED')
      }
    }
  },
  // async setStakeholderDataPublicData({ commit }) {
  //   try {
  //     const response = await getData('api', 'v1/public/brrd');
  //     commit('SET_CONTENT_DATA', response.data.data.data);
  //     commit('SET_RESPONSE', response);
  //   } catch (_) {
  //     commit('SET_FAILED_LOGIN');
  //   }
  // },
  async postStakeholderData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData(
        'api',
        'v1/stakeholders',
        payload,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (err) {
      if (err.response) {
        commit('SET_FAILED_MESSAGE', err.response);
      } else {
        commit('SET_FAILED')
      }
    }
  },
  async putStakeholderData({ commit, rootState }, payload) {
    try {
      const option = {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token,
        },
      };
      const response = await postData(
        'api',
        `v1/stakeholders/${payload.get('id')}`,
        payload,
        option
      );
      commit('SET_RESPONSE', response);
    } catch (err) {
      if (err.response) {
        commit('SET_FAILED_MESSAGE', err.response);
      } else {
        commit('SET_FAILED')
      }
    }
  },

  resetSearch({ commit }) {
    commit('resetState');
  },
};

const mutations = {
  SET_RESPONSE(state, response) {
    state.status = response.data.status;
    state.message = response.data.msg;
  },
  SET_FAILED(state) {
    state.message = 'Network Problem';
    state.status = 0;
  },

  SET_FAILED_MESSAGE(state, error) {
    console.log(error)
    console.log(error.status)
    if (error.status == 422) {
      if (error.data.msg == "validation.unique")
        state.message = "Email / Phone Number is already used!";
      else if (error.data.msg == "validation.email")
        state.message = "Email format is not correct!"
      else {
        state.message = "Fill all required fields!"
      }
      state.status = 0;

    } else {
      state.message = 'Network Problem';
      state.status = 0;
    }
  },

  SET_CONTENT_DATA(state, response) {
    state.stakeholder = response;
    //add property open dropdown
    state.stakeholder.forEach(function (element) {
      element.open_dropdown = 'false';
    });
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
