import Input from '@/components/helper/Input';
import Button from '@/components/helper/Button';
import Textarea from '@/components/helper/Textarea';
import Modal from '@/components/helper/Modal';
import Select from '@/components/helper/Select';
import SwitchCustom from '@/components/helper/Switch';
import UploadFile from '@/components/helper/UploadFile';
import Multiselect from 'vue-multiselect';
import common from "@/mixins/common";
import { mapGetters, mapActions} from 'vuex';


export default {
  components: {
    Input,
    Button,
    Textarea,
    Modal,
    Select,
    Multiselect,
    SwitchCustom,
    UploadFile,
  },
  mixins: [common],
  data: () => ({
    empty_field: false,
  }),
  computed: {
    ...mapGetters({
      lang: "locale/getCurrentLocale",
      isMobile: "getIsMobile",
      loadingPage: "getLoadingPage",
    })
  },
  methods: {
    ...mapActions({
      setLoadingPage: 'setLoadingPage',
    }),

    checkEmptyField(data, validation, isFile, file, inputType) {
      var count_true = 0;
      for (const key in data) {
        //traverse through object's keys
        if (
          (data[key] == "" || data[key] == [] || data[key] == null) &&
          key != "youtube_link"
        ) {
          if(validation.hasOwnProperty(key)){
            validation[key] = true;
            this.empty_field = true;
            count_true += 1;
          }
        } else {
          validation[key] = false;
        }
      }

      if (isFile && file == undefined && inputType != "Edit") {
        this.empty_field = true;
        count_true += 1
        this.showSnackbar({
          type: "error",
          text: "Upload your file!",
        });
      }
      if (count_true == 0) this.empty_field = false
    },
    moveToOther(routerName) {
      this.$router.push(this.$translate({
        name: routerName,
      }));
    },

    generateVideoId(value) {
      if (value == null) return null
      let videoId = null;
      if (value.toLowerCase().includes('?v=')) {
        videoId = value.split('?v=')[1]
        if (videoId.toLowerCase().includes('&ab_channel=')) {
          videoId = videoId.split('&ab_channel=')[0]
        }
      } else if (value.toLowerCase().includes('youtu.be/')) {
        videoId = value.split('youtu.be/')[1]
      } else if (value.toLowerCase().includes('/embed/')) {
        videoId = value.split('/embed/')[1]
      }
      return videoId
    }
  }
};