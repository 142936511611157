
import { routerFilter } from '@/plugins/routes'
import { loadMain, loadPublic } from '@/router/lazyLoad'

const routes = [
    {
		path: '',
		name: 'Home Public',
		meta: {
			requiresAuth: false,
			roleVerif: false,
			role: []
			},
			component: loadMain('homeMain'),
	},
	{
		path: 'contents/:contentType(brrd|edc|events)?',
		name: 'Content List',
		meta: {
			requiresAuth: false,
			roleVerif: false,
			role: []
		},
		component: loadPublic('contentList'),
    },
    {
      path: 'contents/:contentType(brrd|edc|events)?/:slug',
      name: 'Content Detail',
      meta: {
			requiresAuth: false,
			roleVerif: false,
			role: []
      },
      component: loadPublic('contentDetail'),
	},
	{
		path: 'educations',
		name: 'Education List',
		meta: {
			requiresAuth: false,
			roleVerif: false,
			role: []
		},
		component: loadPublic('educationList'),
	},
	{
		path: 'educations/:slug',
		name: 'Education Detail',
		meta: {
			requiresAuth: false,
			roleVerif: false,
			role: []
		},
		component: loadPublic('educationDetail'),
	},
	{
		path: 'events',
		name: 'Event List',
		meta: {
			requiresAuth: false,
			roleVerif: false,
			role: []
		},
		component: loadPublic('eventList'),
	},
	{
		path: 'events/:id',
		name: 'Event Detail',
		meta: {
			requiresAuth: false,
			roleVerif: false,
			role: []
		},
		component: loadPublic('eventDetail'),
	},
	{
		path: 'crowdsourcing-feedback',
		name: 'Feedback Form',
		meta: {
			requiresAuth: false,
			roleVerif: false,
			role: []
		},
		component: loadPublic('feedbackForm'),
	},
	{
		path: 'expert-matching/:contentType(matching|list)?',
		name: 'Expert Matching',
		meta: {
			requiresAuth: false,
			roleVerif: false,
			role: []
		},
		component: loadPublic('expertMatching'),
	},
    
    
]
export default routerFilter(routes)