function loadPublic(view) {
  return () =>
    import(
      /* webpackChunkName: "home-[request]" */
      /* webpackMode: "lazy" */
      `@/components/public/${view}.vue`
    );
}
function loadDashboard(view) {
  return () =>
    import(
      /* webpackChunkName: "home-[request]" */
      /* webpackMode: "lazy" */
      `@/components/dashboard/${view}.vue`
    );
}
function loadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */
      /* webpackMode: "lazy" */
      `@/views/${view}.vue`
    );
}
function loadMain(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */
      /* webpackMode: "lazy" */
      `@/components/main/${view}.vue`
    );
}
function loadAuth(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */
      /* webpackMode: "lazy" */
      `@/components/authentication/${view}.vue`
    );
}
export { loadPublic, loadView, loadDashboard, loadMain, loadAuth };
