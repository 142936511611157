<template>
  <button
    @click="onclick"
    :disabled="isLoading || disabled"
    :class="`r-${color}-${type}-btn s-ptb-${padding} ${padding == 0? 's-plr-0': 's-plr-16'} `"
  >
    <template v-if="!isLoading">
      <slot></slot>
    </template>

    <img
      v-else
      class="s-mlr-auto s-square-26"
      :src="require('@/assets/loading-infinite.svg')"
      alt="loading"
    />
  </button>
</template>
<script>
export default {
  props: {
    color: {
      default: "primary",
    },
    type: {
      default: "filled",
    },
    padding: {
      default: 10,
    },
    isLoading: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    onclick(e) {
      this.$emit("click", e);
    },
  },
};
</script>
